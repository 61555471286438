// src/App.js
import React, { useEffect, useState } from 'react';
import HeroSection from './components/HeroSection';
import AboutUs from './components/AboutUs';
import Technology from './components/Technology';
import Team from './components/Team';
import Navbar from './components/Navbar';
import Contact from './components/Contact.js';
import Footer from './components/Footer.js';
import DemoSection from './components/DemoSections.js';
import Partners from './components/Partners.js';
import DocumentViewer from './components/DocumentViewer';

const App = () => {
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Check if the URL path matches "/documents/gep"
    const path = window.location.pathname;
    
    if (path === '/documents/gep') {
      setDocumentId('gep');
      // No token needed in the URL anymore
      setToken('internal-access');
      setShowDocumentViewer(true);
    }
  }, []);

  // Render DocumentViewer when accessing a document URL
  if (showDocumentViewer) {
    return <DocumentViewer documentId={documentId} token={token} />;
  }

  // Otherwise render the normal website
  return (
    <div>
      <Navbar />
      <HeroSection />
      <AboutUs />
      <Partners />
      <Technology />
      <DemoSection />
      <Team />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;