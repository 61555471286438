// src/components/DocumentViewer.js
import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const DocumentViewer = ({ documentId, token }) => {
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // For the simplified URL structure, we just check for the document ID
  // No token validation needed since the document ID is the only identifier
  const availableDocuments = {
    'gep': {
      title: 'Gender equality plan',
      fileUrl: '/documents/NexThera_GEP.pdf',
      description: 'Gender equality plan'
    }
    // You can add more document types here if needed
  };
  
  useEffect(() => {
    const loadDocument = async () => {
      try {
        setLoading(true);
        
        // Check if document exists
        if (!availableDocuments[documentId]) {
          setError('Document not found');
          setLoading(false);
          return;
        }
        
        // No token validation needed with the simplified URL structure
        
        // Simulating document loading
        setTimeout(() => {
          const docInfo = availableDocuments[documentId];
          setDocument({
            id: documentId,
            title: docInfo.title,
            content: docInfo.description,
            fileUrl: docInfo.fileUrl
          });
          setLoading(false);
        }, 800);
      } catch (err) {
        setError('Failed to load document');
        setLoading(false);
      }
    };
    
    loadDocument();
  }, [documentId, token]);
  
  // Basic styles (you can move these to your CSS file)
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '40px 20px',
      minHeight: '70vh',
    },
    header: {
      marginBottom: '30px',
      paddingBottom: '20px',
      borderBottom: '1px solid #e2e8f0',
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#3b82f6',
    },
    content: {
      lineHeight: '1.6',
    },
    error: {
      color: 'red',
      textAlign: 'center',
      padding: '40px 0',
    },
    loading: {
      textAlign: 'center',
      padding: '40px 0',
    },
    button: {
      display: 'inline-block',
      padding: '10px 15px',
      margin: '20px 0',
      backgroundColor: '#3b82f6',
      color: 'white',
      borderRadius: '5px',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    backButton: {
      display: 'inline-block',
      padding: '10px 15px',
      marginBottom: '20px',
      backgroundColor: '#64748b',
      color: 'white',
      borderRadius: '5px',
      textDecoration: 'none',
      cursor: 'pointer',
    }
  };
  
  const goToHomepage = () => {
    window.location.href = '/';
  };
  
  if (loading) {
    return (
      <div>
        <Navbar />
        <div style={styles.container}>
          <button style={styles.backButton} onClick={goToHomepage}>
            Back to Homepage
          </button>
          <div style={styles.loading}>Loading document...</div>
        </div>
        <Footer />
      </div>
    );
  }
  
  if (error) {
    return (
      <div>
        <Navbar />
        <div style={styles.container}>
          <button style={styles.backButton} onClick={goToHomepage}>
            Back to Homepage
          </button>
          <div style={styles.error}>{error}</div>
        </div>
        <Footer />
      </div>
    );
  }
  
  return (
    <div>
      <Navbar />
      <div style={styles.container}>
        <button style={styles.backButton} onClick={goToHomepage}>
          Back to Homepage
        </button>
        
        <div style={styles.header}>
          <h1 style={styles.title}>{document.title}</h1>
        </div>
        
        <div style={styles.content}>
          <p>{document.content}</p>
          
          {/* For PDF documents, add a download button */}
          <a 
            href={document.fileUrl} 
            style={styles.button} 
            target="_blank" 
            rel="noopener noreferrer"
          >
            View PDF Document
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DocumentViewer;